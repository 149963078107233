import React, { SyntheticEvent, useState } from "react";

import { navigate } from "@reach/router";
import { POLLFORM_SET_FORM_VALUE_TYPE } from "actions/pollform";
import PrimaryButton from "components/common/Buttons/Primary";
import TagButton from "components/common/Buttons/Tag";
import BaseForm from "components/common/Forms/BaseFrom";
import BaseTextInput from "components/common/Inputs/BaseTextInput";
import TopSection from "components/Sections/Top";
import { PageProps } from "gatsby";
import usePollFormContext from "hooks/common/usePollFormContext";
import { AiFillBulb } from "react-icons/ai";

const samples = [
  { value: "嗌二人套餐外賣自己食晒😋🤰🏻" },
  { value: "自己整3道菜套餐👩🏼‍🍳🍝" },
  { value: "GoPro Hero 10 開箱" },
  { value: "西貢：灣仔南營地" },
  { value: "熱杏霜🤣🤣🤣🤣" },
  { value: "Paella 🥘" },
  { value: "Shabu shabu 打邊爐🍲" },
  { value: "20種唔同方法唱聖誕歌" },
  { value: "點樣可以強制試一take pass" },
  { value: "香港芬蘭一日遊" },
];

interface IProps extends PageProps<any, any, any> {
  params: {
    index: string;
  };
}

const DareMeOptionsPage: React.FunctionComponent<IProps> = ({ params, location }) => {
  const search = new URLSearchParams(location.search);
  const value = search.get("value") || "";
  const [state, setState] = useState(value || "");
  const { pollForm, pollFormDispatch } = usePollFormContext();

  const onTextChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const target = e.target as HTMLInputElement;

    setState(target.value);
  }

  const onTagClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    const target = e.target as HTMLButtonElement;

    setState(target.value);
  }

  const onSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const target = e.target as any;
      const field = `options-${params.index}`;
      const name = target![field].name.split('-');
      const key = name[0];
      const index = Number(name[1] - 1);
      let newOptions = [...pollForm.options];

      newOptions[index] = target![field].value;
      pollFormDispatch({
        type: POLLFORM_SET_FORM_VALUE_TYPE,
        payload: { 
          ...pollForm, 
          [key]: newOptions
      }});
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <TopSection
        title={`DareMe Option ${params.index}`}
        leftComponent={true}
        rightComponent={<AiFillBulb className="text-success-600" />}
      />
      <BaseForm className="min-h-[80vh] max-w-2xl flex flex-col content-between mx-auto px-4 py-4 sm:px-6 lg:max-w-7xl lg:px-8 lg:py-8 " onSubmit={onSubmit}>
        <BaseTextInput
          name={`options-${params.index}`}
          value={state}
          maxLength={20}
          onChange={onTextChange}
        />

        <div className="flex-grow flex flex-col justify-center items-start">
          <h4 className="text-neutral-800">What other creators dared?</h4>
            {samples.map((sample) => {
              return (
                <>
                  <br />
                  <TagButton value={sample.value} onClick={onTagClick}>{sample.value}</TagButton>
                </>
              );
            })}
        </div>

        <PrimaryButton type="submit" className="w-full mt-4 px-3 py-2 bottom-0" disabled={state.length === 0}>
          Save
        </PrimaryButton>
      </BaseForm>
    </>
  );
};

export default DareMeOptionsPage;
